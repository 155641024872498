@import "../../styles/common"

.product-vertical-category
  margin-top: 30px
  .category-header
    height: 68px
    position: relative
    border-top-left-radius: 40px
    border-bottom-right-radius: 40px
    background-size: 100%
    background-repeat: no-repeat
    background-position: center center
    overflow: hidden
    margin-bottom: 15px
    .overlay
      z-index: 1
      position: absolute
      top: 0
      left: 0
      height: 100%
      width:  100%
      background: linear-gradient(180deg, rgba(0,0,0,.1) 0%, rgba(0,0,0,.8) 100%)
      &.no-bg
        background: rgb(3,110,253)
    .header
      z-index: 2
      position: absolute
      bottom: 16px
      left: 16px
      color: white
      font-family: NotoSans
      font-weight: bold
      font-size: 24px

  .products-list
    .product
      display: flex
      min-width: 0
      flex-wrap: nowrap
      align-items: center
      height: 64px
      border-bottom: 1px solid rgb(242,242,242)
      &:last-child
        border-bottom: none
      .image
        width: 50px
        height: 64px
        flex-shrink: 0
        margin-left: 8px
      .product-name
        flex-grow: 1
        font-family: SourceSansPro
        font-size: 16px
        margin: 0 7px
        min-width: 0
        .name
          line-height: 16px
          color: $color-text-1
        .description
          p
            margin: 0
          color: $color-text-2
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis

      .price
        flex-shrink: 0
        display: flex
        align-items: center
        flex-wrap: nowrap
        color: $color-secondary
        font-weight: bold
        font-family: SourceSansPro
        font-size: 16px
        margin-right: 28px
        .value
          margin-right: 16px
        .icon
          color: $color-secondary
          width: 28px
          height: 28px
        &.unavailable
          white-space: nowrap
          color: $color-highlight
          font-weight: bold
