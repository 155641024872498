@import "../../styles/common"

.drawer-menu
  .current-orders-short
    margin-bottom: 20px
    margin-top: -20px

.after-selector
  margin-top: 40px

.current-orders-short
  color: white

  .cart-icon
    width: 24px
    height: 24px
    margin-right: 10px

  .order
    display: flex
    align-items: center
    font-family: SourceSansPro
    padding: 18px
    margin-top: 2px

    .order-number

    .status
      text-align: right
      flex-grow: 1
      font-weight: normal
      .checkout-icon
        width: 16px
        height: 16px
        background-size: contain
        vertical-align: -3px
        margin-left: 10px