@import "../styles/common"

.search-container
  .search-input-container
    height: 76px
    background: white
    z-index: $search-z-index + 1
    position: fixed
    left: 0
    top: 0
    width: 100%
    @include standard-box-shadow

    .input-group
      display: flex
      align-items: center
      .icon
        width: 24px
        height: 24px
        margin-left: 16px
      input
        border: none
        box-shadow: none
        font-family: SourceSansPro
        font-size: 16px
        height: 76px

  .search-result-container
    padding: 106px 25px 0
    z-index: $search-z-index
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: #FBFDFF
    transition: opacity .2s, transform linear .2s
    display: none
    overflow-y: auto
    &.show
      display: block

    .search-result-container-inner
      padding: 43px 25px 25px

    .popular-list
      padding-top: 10px
      .header
        font-family: SourceSansPro
        font-weight: 600
        font-size: 14px
        margin-bottom: 5px

    ul.results-list
      margin: 0
      padding: 0
      li
        list-style: none
        display: flex
        align-items: center
        line-height: 50px
        cursor: pointer
        .icon
          width: 18px
          height: 18px
          margin-top: 2px

        .term
          margin-left: 11px
          font-family: SourceSansPro
          font-size: 16px
