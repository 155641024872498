@import "../styles/common"

.select-sector-page
  .select-sector-container
    padding: 18px

    .select-sector
      font-size: 16px
      font-weight: bold
      font-family: SourceSansPro
      text-align: center
      margin-top: 38px

  .sector-list-container
    max-height: 300px // Adjust this value based on your preference
    overflow-y: auto // To make space for the sticky button

  .sticky-button-container
    position: sticky
    padding-top: 25px
    box-shadow: none!important

  .big-primary
    border: none!important
    color: white!important
    font-family: NotoSans
    font-width: bold
    box-shadow: none!important
    padding: 18px
    background-color: $color-primary!important
    &.selectedSectorButton
      background-color: $color-secondary!important

  .headerPopup
    font-size: 20px
    margin-top: 30px


