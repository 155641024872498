@import "../../styles/common"

.help-content
  padding: 20px 16px 0

  font-family: SourceSansPro
  font-size: 16px
  color: $color-dark-gray

  .error-text
    color: $color-error-bright

  ul
    margin-bottom: 0

  p
    margin: 10px 0
    padding: 0
    font-size: 14px
    &.bigger
      font-size: 16px

  .big-primary
    font-family: NotoSans
    font-weight: bold
    font-size: 18px
    margin: 33px 0

  h3
    font-family: SourceSansPro
    font-weight: bold
    font-size: 16px

  ul.nav-menu
    margin: 0 0 20px 0
    padding: 0
    li
      padding: 0
      list-style: none
      display: flex
      align-items: center
      justify-content: space-between
      border-bottom: 1px solid #B7B7B7
      a, span
        display: block
        flex-grow: 1
        color: inherit
        text-decoration: none
        padding: 17px 0
      .icon
        width: 24px
        height: 24px
        flex-shrink: 0

  .faq-accordion
    margin: 0 0 20px 0
    .item
      padding: 10px 0
      border-bottom: 1px solid #B7B7B7
      &:last-child
        border-bottom: none
      .item-header
        display: flex
        align-items: center
        justify-content: space-between

        .item-header-text
          display: block
          flex-grow: 1
        .icon

          width: 24px
          height: 24px
          flex-shrink: 0

      .item-content
        overflow: hidden
        transition: all .05s linear
        margin: 0
        opacity: 0
        padding: 0
        font-size: 0

      &.active
        .item-header
          color: $color-primary
        .item-content
          opacity: 1
          padding: 10px 0 0
          font-size: 14px

  .order-number-header
    display: flex
    align-items: center
    justify-content: space-between
    margin: 30px 0
    h3
      margin: 0
      padding: 0

  .input-container
    input
      width: 100%
      display: block
      font-family: inherit
      font-size: 16px

  .form-label
    margin-top: 20px
    display: block

  .form-check
    margin-bottom: 10px!important

.problem-button
  padding: 10px
  a
    text-decoration: none
  .comment
    margin-bottom: 10px
    font-size: 16px
    font-family: SourceSansPro
    color: $color-gray-4

.problem-form
  .checkbox-list
    margin-top: 16px
