@import "../../styles/common"

.product-options
  opacity: 1
  &.loading
    opacity: .6
  transition: opacity .3s

  margin-bottom: 64px
  @include not-mobile
    margin-bottom: 0

  .image-block
    margin: 10px 0

  .description
    margin: 10px 0
    font-size: 14px
    font-family: SourceSansPro

  .buttons
    display: flex
    justify-content: space-between
    .quantity
      display: flex
      align-items: center
      .icon
        width: 24px
        height: 24px
        color: $color-secondary
      .value
        margin: 0 11px
        font-size: 16px
        font-family: NotoSans

    .total
      display: flex
      align-items: center
      font-weight: bold
      font-size: 16px

      .value
        font-family: SourceSansPro
        margin-right: 16px
        color: $color-dark-gray

      .add-button
        display: flex
        align-items: center
        color: white
        background-color: $color-secondary
        height: 50px
        padding: 15px
        border-radius: 25px
        .text
          margin-top: -1px
        .icon
          color: $color-secondary
          width: 28px
          height: 28px


