@import "styles/common"

*
  box-sizing: border-box

.page-container
  max-width: 100%
  min-height: 100%
  height: 100%
  @include not-mobile
    width: $not-mobile-container-width
    margin: 0 auto







