@import "../../styles/common"

.product-list-container
  margin-top: 40px
  width: 100%
  overflow: hidden
  .header-container
    margin-bottom: 16px
    padding: 0 18px
    display: flex
    justify-content: space-between
    align-items: center
    .main-header
      display: flex
      align-items: center
      .icon
        margin-right: 12px
        width: 12px
        height: 12px
      .header
        color: $color-text-1
        font-family: SourceSansPro
        font-weight: bold
        font-size: 18px
    .sub-header
      font-family: Roboto
      font-size: 16px
      font-weight: 500
      text-decoration: underline

  &:not(.loading)
    .main-header .icon
      color: $color-text-1

  .product-name
    .description
      display: none

  &.loading
    @include opacity-blink
    .main-header .header,
      .sub-header
      background: $color-light-gray
      height: 13px
      border-radius: 3px

    .main-header
      .header
        width: 128px
      .icon
        color: $color-text-2
    .sub-header
      width: 100px

    .product-name
      background: #ECF0F3
      height: 20px!important
      margin-bottom: 50px!important
      color: #ECF0F3

    .price
      .value
        color: #ECF0F3!important
        height: 20px!important
        background: #ECF0F3!important
      .icon
        background: #ECF0F3!important
        border-radius: 50px

  .product-list
    display: flex
    overflow: auto
    padding: 10px 18px 10px
    @include no-scrollbar
    .product
      margin-right: 8px
      background: white
      width: 154px
      border-radius: 12px

      @include standard-box-shadow-straight

      .image
        width: 154px
        height: 154px
        overflow: hidden
        border-radius: 12px
        padding: 8px 0

      .product-name
        color: $color-text-3
        font-family: SourceSansPro
        font-size: 16px
        margin: 12px 7px 0
        height: 42px
        line-height: 17px

      .price
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0 9px 9px
        color: $color-secondary
        .value
          font-family: SourceSansPro
          font-weight: bold
          font-size: 16px
        .icon
          width: 28px
          height: 28px
        &.unavailable
          white-space: nowrap
          color: $color-highlight
          font-weight: bold
