@import "../../styles/common"

.my-seat-page 
    font-family: SourceSansPro
    color: $color-gray-3

    .welcome-header 
        @include top-gradient-background-opacity
        height: 200px
        color: white
        font-family: NotoSans
        display: flex 
        flex-direction: column
        justify-content: center
        align-items: center
        border-bottom-left-radius: 50%
        border-bottom-right-radius: 50%
        .line-1
            font-size: 26px
            font-weight: bold
        .line-2
            font-size: 16px
        .line-3
            margin-top: 5px
            img 
                height: 40px

    .items 
        display: flex 
        flex-direction: column
        align-items: center
        margin-top: 50px
        gap: 50px
        
        .item
            text-align: center

            .label 
                font-size: 16px

            .name 
                font-size: 64px
                font-weight: bold

