@import "../../../styles/common"

.geo-popup
  display: flex
  flex-direction: column
  align-items: center
  gap: 10px

  .popup-icon
    color: $color-tertiary
    width: 50px
    height: 50px
    svg
      width: 100%
      height: 100%
  .header
    text-align: center
    color: $color-tertiary!important
    font-weight: bold!important
    font-size: 16px!important
    font-family: NotoSans!important
  .message
    text-align: center
    font-size: 16px
    font-family: NotoSans
  .buttons
    display: flex
    flex-direction: column
    gap: 10px
    width: 100%
    button
      padding-left: 20px
      padding-right: 20px
      width: 100%