@import "../styles/common"

.down-popup-container
  .down-popup-background
    position: fixed
    left: 0
    top: 0
    width: 100%
    height: 100%
    transition: opacity .3s
    background: rgba(46, 46, 46)
    opacity: 0

    &.show
      opacity: .2

  .down-popup-content
    position: fixed

    border-radius: 20px 20px 0 0
    @include not-mobile
      border-radius: 20px

    width: 100%
    left: 0
    background: white
    bottom: 0
    transition: bottom .3s, transform .3s
    transform: translateY(100%)
    max-height: 90%
    overflow-y: auto

    &.show
      bottom: 0
      transform: translateY(0)
      @include not-mobile
        bottom: 50%
        transform: translateY(50%)

    @include not-mobile
      width: 400px
      left: 50%
      margin-left: -200px

    .down-popup-content-inner
      position: relative
      padding: 25px 16px

    .header
      font-size: 28px
      padding-right: 15px
      font-family: SourceSansPro

    .button-close
      position: absolute
      right: 16px
      top: 20px
      .icon
        width: 24px
        height: 24px

    form
      margin-top: 29px

