@import "../../styles/common"
@import "icons"

.cart-sticky-container
  height: 100px
  .down-stick
    z-index: $cart-sticky-z-index!important

button.cart-sticky
  display: flex!important
  justify-content: space-between
  align-items: center
  padding: 0 24px

  .cart-qty
    flex-shrink: 0
    .cart-qty-inner
      position: relative
      height: 36px
      .icon
        left: 0
        top: 0
        position: absolute
        width: 36px
        height: 36px
        z-index: 1
      .qty
        height: 24px
        min-width: 24px
        top: 20px
        left: 20px
        position: absolute
        font-family: SourceSansPro
        font-size: 16px
        font-weight: 600
        background: white
        border-radius: 50%
        color: $color-dark-gray
        z-index: 2

  .label
    font-family: SourceSansPro
    font-size: 16px
    flex-grow: 1

  .price
    text-align: right
    font-family: SourceSansPro
    font-size: 18px
    font-weight: bold
