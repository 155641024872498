@import "../styles/common"

.no-connection
  margin-bottom: -25px

  svg
    display: block
    width: 32px
    height: 32px


  font-family: SourceSansPro
  color: $color-gray-4

  h3
    margin-top: 10px
    font-weight: bold
    font-size: 16px
  p
    margin-top: 10px
    margin-bottom: 30px
