@import "../styles/common"

.page-unavailable
  color: $color-gray-3
  font-family: SourceSansPro
  text-align: center
  padding: 0 30px

  .img
    background-repeat: no-repeat
    background-size: 100%
    width: 200px
    height: 200px
    margin: 80px auto 0

  h2
    font-size: 44px
    font-weight: bold
    &.smaller
      font-size: 20px
  h3
    font-size: 16px
    font-weight: bold
  .comment
    font-size: 16px
    margin: 35px 0

  .bb-button-container
    padding: 0 0 16px!important
