@import "../styles/common"

.page-header
  @include top-gradient-background-header
  border-bottom-right-radius: 40px
  border-bottom-left-radius: 40px
  height: 96px
  padding-top: 55px

  .header
    position: relative
    text-align: center
    font-family: NotoSans
    font-weight: bold
    font-size: 18px
    color: white

    .icon
      width: 24px
      height: 24px
      position: absolute
      left: 16px
      top: 5px
      cursor: pointer

