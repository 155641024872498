@import "../../styles/common"

.order-is-empty
  padding: 27px 0
  .checkout-icon
    width: 70px
    height: 70px
    margin: 0 auto
    display: block

  .label
    color: $color-gray-3
    font-family: SourceSansPro
    font-size: 16px
    text-align: center
    margin-top: 19px

