@import "../styles/common"

.splash-screen
  z-index: $splash-screen-z-index
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%

  background-image: url(../assets/splash/background.jpg)
  background-size: auto 100%
  background-position: center center

  transition: opacity .3s
  opacity: 0

  &.show
    opacity: 1

  .upper-background
    position: fixed
    z-index: $splash-screen-z-index+1
    top: 0
    left: 0
    width: 100%
    height: 100%
    @include top-gradient-background-splash
    background-size: 300% 300%
    animation: splash-gradient 5s linear infinite
    transition: background .3s

  .label
    position: fixed
    z-index: $splash-screen-z-index + 2
    font-family: Impact
    font-size: 30px
    color: white
    text-align: center
    letter-spacing: 5px
    width: 100%
    top: 40%

  @include not-mobile
    background-size: 100% auto


@keyframes splash-gradient
  0%
    background-position: 0 50%

  50%
    background-position: 100% 50%

  100%
    background-position: 0 50%
