@import "../../styles/common"
@import "icons"

.PhoneInputCountry
  padding-left: 10px

.rectangle-location
  display: flex
  align-items: center
  justify-content: flex-start
  padding-left: 5px
  height: 40px
  width: 100%
  background-color: #E2F4E9
  border-radius: 6px
  color: black
  font-size: 16px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.radio-container
  display: flex
  flex-direction: column

.normal, .selected
  position: relative
  margin-bottom: 12px
  cursor: pointer
  font-size: 22px
  user-select: none

.sector-box
  display: flex
  flex-direction: column
  align-items: start

.buttons-tip
  color: red


.sector-box-title
  .checkout-icon
  .texts
    margin-bottom: 10px

.normal input, .selected input
  position: absolute
  opacity: 0
  cursor: pointer
  height: 0
  width: 0

.checkmark
  position: absolute
  top: 0
  left: 0
  height: 25px
  width: 25px
  background-color: black
  border-radius: 50%

.normal:hover input ~ .checkmark, .selected:hover input ~ .checkmark
  background-color: green

.normal input:checked ~ .checkmark, .selected input:checked ~ .checkmark
  background-color: green

.checkmark:after
  content: ""
  position: absolute
  display: none

.normal input:checked ~ .checkmark:after, .selected input:checked ~ .checkmark:after
  display: block

.normal .checkmark:after, .selected .checkmark:after
  top: 9px
  left: 9px
  width: 8px
  height: 8px
  border-radius: 50%
  background: white


.page-header .order-success
  width: 120px
  height: 120px
  top: -18px
  background: url(./../../assets/checkout/success-order.png) no-repeat
  background-position: center center
  background-size: 100% 100%
  position: absolute
  left: 50%
  transform: translateX(-50%)

label.form-label
  font-size: 16px
  font-family: SourceSansPro
  color: $color-text-1

.input-container
  border-radius: 4px
  border: 1px solid #c4c4c4
  height: 44px
  overflow: hidden
  width: 100%

  &.error
    border-color: $color-error

  input, textarea, select
    border: none
    outline: none
    height: 44px
    font-size: 16px
    font-family: inherit
    padding: 0 17px
    display: block
    width: 100%

  textarea
    display: block
    width: 100%
    resize: none
    padding: 10px
    height: 80px

  &.textarea
    height: auto

.input-error
  color: $color-error
  font-family: SourceSansPro
  font-size: 14px
  padding-top: 5px

.checkout-contents
  padding: 16px

  &.order-success
    margin-top: 60px

  .header-comment
    text-align: center
    margin: 0 0 25px
    font-family: SourceSansPro
    font-size: 16px

  .box
    @include standard-box-shadow
    border-radius: 8px

  h2
    font-family: NotoSans
    font-weight: bold
    font-size: 18px
    margin: 10px 0 16px 0
    padding: 0
    color: $color-text-1

  h3
    font-family: SourceSansPro
    font-weight: bold
    font-size: 16px
    color: $color-text-1

  .cart
    margin-top: 16px
    padding: 16px
    font-family: SourceSansPro

    .items
      .item
        display: flex
        border-bottom: 1px solid #E8E8E8
        &:last-child
          border-bottom: none
        padding: 12px 0
        opacity: 1
        &.loading
          opacity: .6
        transition: opacity .3s
        .qty-col
          margin-right: 16px
          color: $color-text-1
        .name-col
          color: $color-text-1
          .name
            font-size: 16px
          .extra
            color: $color-text-2
            font-size: 12px
        .price-col
          color: $color-text-1
          flex-grow: 1
          text-align: right
          .price
            font-size: 16px

          .qty
            margin-top: 13px
            text-align: right
            white-space: nowrap
            .icon, .value
              display: inline-block
              vertical-align: middle
            .icon
              width: 24px
              height: 24px
              color: $color-secondary
            .value
              margin: 0 11px

        &.not-available
          .name-col .name, .qty-col, .price-col .price, .extra
            opacity: .5
          .product-error
            color: $color-error
            font-size: 14px
          .remove-button
            display: inline-block
            cursor: pointer
            color: $color-error
            font-weight: bold
            background: rgba(178, 0, 0, .2)
            border-radius: 40px
            padding: 5px 12px
            font-family: SourceSansPro
            font-size: 12px

    .price-extra
      .item
        display: flex
        justify-content: space-between
        color: $color-text-1
        font-size: 16px
        margin: 8px 0
        .value
          &.focus
            background: $color-dark-green-1
            color: white
            padding: 0 5px
            border-radius: 5px

      .item-comment
        display: flex
        align-items: center
        .icon
          width: 21px
          height: 21px
        .comment
          margin-left: 6px
          color: $color-text-2
          font-size: 11px

    .total
      font-family: NotoSans
      font-weight: bold
      font-size: 18px
      display: flex
      justify-content: space-between
      margin: 32px 0 16px
      .comment
        display: inline-block
        color: $color-text-2
        margin-left: 10px
        font-size: 11px
        font-weight: normal

  .cart-summary
    border-radius: 8px
    background: $color-tertiary
    height: 58px
    display: flex
    align-items: center
    padding: 0 16px
    margin-bottom: 16px
    cursor: pointer

    .checkout-icon
      width: 24px
      height: 24px
      margin-right: 7px

    .label, .price
      color: white
      font-family: SourceSansPro
      font-weight: bold
      font-size: 16px

    .price
      flex-grow: 1
      text-align: right

  .summary-row-comment
    font-size: 16px
    font-family: SourceSansPro
    margin: 13px 0




  .summary-rows
    color: white
    padding: 20px
    border-radius: 8px
    background: $color-dark-green-1
    &.danger
      background: $color-highlight

  .summary-rows, .order-summary-box
    .summary-row
      margin-bottom: 14px
      &:last-child
        margin-bottom: 0
      font-family: SourceSansPro
      font-size: 16px
      display: flex
      align-items: center
      .checkout-icon
        width: 24px
        height: 24px
        margin-right: 10px
      .right
        text-align: right
        flex-grow: 1
      &.comment
        font-size: 14px
      .change-option
        flex-grow: 1
        text-align: right

  .box.checkout-form
    padding: 16px
    h3
      margin: 0
      padding: 10px 0

    .place-selector
      margin-bottom: 20px
      .input-line
        display: flex
        align-items: center

        .sector-name
          margin-left: 11px

      .place-input
        display: flex
        align-items: center
        .separator
          @extend .checkout-icon, .checkout-icon-sector-input-separator
          width: 8.5px
          height: 21px
          flex-shrink: 0
        input
          grow: 1
        &.full-width input
          width: 100%

    .comment
      color: $color-text-1
      opacity: .6
      font-size: 12px

    .textarea-label
      margin-top: 16px

    .mobile-phone-input
      margin: 16px 0
      input
        width: 100%
        display: block
        font-family: inherit
        font-size: 16px

  .sector-container
    margin-top: 16px
    .sector
      color: $color-text-1
      background: $color-tertiary-lighten
      border-radius: 8px
      display: flex
      align-items: flex-start
      padding: 10px
      font-family: SourceSansPro
      font-size: 16px
      .checkout-icon
        width: 24px
        height: 24px
      .texts
        margin-left: 10px
        strong
          margin-right: 10px

  .box.payment-form
    margin-top: 27px
    padding: 24px 16px
    background: white
    .error
      color: $color-error-bright
    .agree-label
      line-height: 17px
    h3
      font-family: SourceSansPro
      font-size: 16px
      font-weight: bold
      margin-bottom: 16px

    .form-check
      font-family: SourceSansPro
      font-size: 16px
      color: $color-dark-gray
      font-weight: normal
      &:last-child
        margin-bottom: 0

      .form-check-input[type=radio], .form-check-input[type=checkbox]
        &:checked+label
          color: $color-dark-gray
          font-weight: normal

      .image
        flex-grow: 1
        text-align: right
        img
          display: inline-block
          height: 33px

  .order-progress
    margin: 50px 0 0
    padding: 0 16px
    font-family: SourceSansPro
    font-size: 16px
    color: $color-dark-gray
    .points
      .point
        display: flex
        .checkout-icon
          width: 24px
          height: 24px
          margin-right: 16px

      .line
        height: 64px
        border-left: 3px solid #DCDCDC
        margin-left: 10px
        &.active
          border-color: $color-secondary


  .order-number-container
    margin-top: 22px
    font-family: SourceSansPro
    border-radius: 8px
    background: $color-tertiary-lighten
    color: $color-text-1
    padding: 14px 8px
    text-align: center
    .number-comment
      font-size: 14px
    .number
      font-size: 32px
      font-weight: bold
      font-size: 64px
    .info-comment
      text-align: center
      font-size: 14px
    .info
      margin-top: 5px
      align-items: center
      justify-content: center
      display: flex
      column-gap: 5px
      .checkout-icon
        width: 24px
        height: 24px

  .problem-container
    margin-top: 30px
    margin-bottom: 20px
    color: $color-secondary
    text-align: center
    font-family: SourceSansPro
    font-size: 16px
    text-decoration: underline
    font-weight: bold

  .product-danger-container
    display: flex
    align-items: center

    .checkout-icon
      width: 19px
      height: 19px
      flex-shrink: 0
      margin-right: 10px

    color: $color-error
    font-family: SourceSansPro
    font-size: 12px

.order-bottom-buttons
  margin-top: 30px
  display: flex
  flex-direction: column
  row-gap: 16px


.reservation-container
  margin-top: 20px

  .selected-reservation-container
    margin-top: 20px
    
    .date 
      display: flex 
      justify-content: space-between

    .change-button-container
      color: gray
      text-decoration: underline
      cursor: pointer
      display: flex 
      justify-content: flex-end

.reservations-time-popup
  margin-top: 20px
  .label-comment
    font-size: 12px
    line-height: 12px
  .reservation-times
    .reservation-times-input-container
      margin-top: 20px

      .select-container 
        select 
          font-size: 14px
          padding: 5px
          width: 100%
          display: block 
          border-radius: 4px
          border: 1px solid #c4c4c4
  .no-options
    font-size: 16px
    margin-bottom: 15px
