@import "../styles/common"

.main-page-header-wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  z-index: $header-z-index

.main-page-header
  position: relative
  @include not-mobile
    margin: 0 auto
    width: $not-mobile-container-width
    max-width: 100%

  .wrapper-container
    position: absolute
    left: 0
    top: 0
    width: 100%
    min-height: 161px
    z-index: 1
    @include top-gradient-background-opacity
    border-bottom-left-radius: 40px
    border-bottom-right-radius: 40px

    .main-label
      padding-top: 80px
      font-size: 18px
      color: white
      font-family: NotoSans
      font-weight: bold
      text-align: center

    .sub-label
      margin-top: 4px
      color: white
      font-size: 14px
      font-family: NotoSans
      text-align: center

    .sector
      font-family: NotoSans
      font-size: 12px
      color: white
      text-align: center
      cursor: pointer

      .icon
        height: 24px
        width: 24px
        vertical-align: -8px

  .top-container
    left: 0
    top: 0
    width: 100%
    position: absolute
    z-index: 2
    background: none

    .container
      width: 95%
      margin: 0 auto

    .icons
      line-height: 45px

      &.left
        text-align: left
      &.right
        text-align: right
      .icon
        cursor: pointer
        width: 24px
        height: 24px
        &.search
          width: 20px
          height: 20px

    .title
      padding-top: 30px

.orderly-main-label
  text-align: center
  font-family: Impact
  color: white
  font-size: 24px
  letter-spacing: 5px
  img
    height: 40px
    max-width: 90%
    display: block
    margin: 0 auto

.drawer-container
  .orderly-main-label
    img
      height: 90px