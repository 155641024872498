@import "colors"
@import "fonts"
@import "icons"
@import "vars"


html, body, #root
  background: $color-background
  height: 100%
  min-height: 100%

@mixin not-mobile
  @media (min-width: 480px)
    @content

@mixin top-gradient($opacity-start, $opacity-end)
  background: rgb(var(--header-primary-color-rgb, 255,255,255))
  background: linear-gradient(135deg, rgba(var(--header-primary-color-rgb, 255,255,255) ,.95) 0%, rgba(var(--header-secondary-color-rgb, 255,255,255),.95) 50%, rgba(var(--header-tertiary-color-rgb, 255,255,255),.95) 100%)

@mixin top-gradient-background-opacity
  @include top-gradient(.95, .95)

@mixin top-gradient-background-splash
  @include top-gradient(.35, .75)

@mixin top-gradient-background-header
  @include top-gradient(1, 1)

@mixin standard-box-shadow-y($y, $o: .1)
  box-shadow: 0 $y 20px 0 rgba(28, 110, 186, $o)

@mixin standard-box-shadow
  @include standard-box-shadow-y(4px)

@mixin standard-box-shadow-straight
  @include standard-box-shadow-y(0)

@mixin standard-box-shadow-straight-strong
  @include standard-box-shadow-y(0, .4)

@mixin no-scrollbar
  &::-webkit-scrollbar
    display: none
  -ms-overflow-style: none
  scrollbar-width: none

.btn-primary
  border: none!important
  background-color: $color-primary!important
  color: white
  font-family: NotoSans
  font-width: bold
  box-shadow: none!important

.btn.big-primary
  display: block
  width: 100%
  font-size: 18px
  text-align: center
  padding: 12px
  border-radius: 80px

  &.outline
    background-color: transparent!important
    border: 2px solid $color-primary!important
    color: $color-primary
    font-weight: bold
  
.form-check-container
  display: flex
  flex-direction: column
  gap: 10px
  .form-check.primary
    margin-bottom: 0

.form-check.primary
  margin-bottom: 10px
  display: flex
  align-items: center

  .form-check-input[type=radio], .form-check-input[type=checkbox]
    width: 0
    height: 0

    &+span
      display: inline-block
      flex-shrink: 0
      @extend .icon
      width: 24px
      height: 24px
      svg
        display: block 
    
    border: none
    background-color: transparent
    outline: none
    box-shadow: none
    border-radius: 0

    &[name=product-options]+span
      width: 16px
      height: 16px

    &+label,
    &+span+label
      user-select: none

    &:not(:checked)
      &[type=radio]
        @extend .icon-radio-primary
      &[type=checkbox]
        @extend .icon-checkbox-primary

    &:checked
      &[type=radio]
        @extend .icon-radio-primary-active
      &[type=checkbox]
        @extend .icon-checkbox-primary-active

      &+label,
      &+span+label,
      &+span
        color: $color-secondary

      &+label,
      &+span+label
        font-weight: bolder

    &:disabled
      &[type=radio]
        @extend .icon-radio-primary-disabled
      &[type=checkbox]
        @extend .icon-checkbox-primary-disabled

      &+label,
      &+span+label
        color: #A1A1A1

  .form-check-label
    font-family: SourceSansPro
    font-size: 14px
    margin-left: 15px
    line-height: 30px
    flex-grow: 1


@keyframes opacity-blink
  0%
    opacity: 1

  50%
    opacity: .3

  100%
    opacity: 1


@mixin opacity-blink
  animation: opacity-blink 3s linear infinite

.opacity-blink
  @include opacity-blink

@keyframes rotating-animation
  from
    transform: rotate(0deg)

  to
    transform: rotate(360deg)

@mixin rotating
  animation: rotating-animation 2s linear infinite

.spin
  @include rotating

.bb-container
  min-height: 100%
  display: flex
  flex: 1
  flex-direction: column

  .bb-button-container
    width: 100%
    margin-top: auto
    padding: 0 16px 16px

a
  text-decoration: none!important

.with-loading-overlay
  opacity: 1
  transition: opacity .3s
  position: relative

  .loading-overlay
    display: none
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    align-items: center
    justify-content: center
    .icon
      @include rotating

  &.loading .loading-overlay
    display: flex

  &.loading
    opacity: .3
