$color-primary: rgb(var(--primary-color-rgb, 7,88,210))
$color-secondary: rgb(var(--secondary-color-rgb, 0,169,68))
$color-tertiary: rgb(var(--tertiary-color-rgb, 0,0,0))
$color-tertiary-lighten: rgb(var(--tertiary-color-lighten-rgb, 0,0,0))

$color-background: rgb(var(--background-color-rgb, 252,252,252))
$color-text-1: rgb(var(--text-1-color-rgb, 0,0,0))
$color-text-2: rgb(var(--text-2-color-rgb, 0,0,0))
$color-text-3: rgb(var(--text-3-color-rgb, 0,0,0))


$color-dark-green-1: $color-tertiary
$color-dark-green-2: $color-tertiary
// $color-light-green: rgb(0, 169, 68, 0.2)
// $color-ultra-light-green: rgb(0, 169, 68, 0.1)
// $color-light-green-1: rgb(226,243,232)
$color-highlight: rgb(234,112,0)
$color-error: rgb(178,0,0)
$color-error-bright: rgb(216,13,0)
$color-highlight-2: rgb(44,209,41)
$color-highlight-3: rgb(235,115,28)
$color-dark-gray: rgb(52,52,52)
$color-gray-1: rgb(124,124,124)
$color-gray-2: rgb(183,183,183)
$color-gray-3: rgb(78,78,78)
$color-gray-4: rgb(52,52,52)
$color-gray-5: rgb(82,82,82)
$color-light-gray: rgb(236,240,243)


$drawer-title-color: rgb(52,52,52)
$drawer-comment-color: rgb(78,78,78)
$drawer-separator-color: rgb(183,183,183)


