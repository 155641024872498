@import "../styles/common"

.service-unavailable-screen
  background-color: $color-light-gray
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: $service-unavailable-z-index

  .label
    font-family: Impact
    font-size: 30px
    color: $color-secondary
    letter-spacing: 5px
    width: 100%
    text-align: center
    margin-top: 124px

  .icon
    margin: 57px auto
    width: 56px
    height: 56px
    display: block

  .header
    font-family: SourceSansPro // todo Barlow
    font-weight: bold
    font-size: 20px
    text-align: center
    margin: 56px auto
    width: 80%

  .sub-header
    font-family: SourceSansPro // todo Barlow
    font-size: 16px
    text-align: center
    margin: 45px auto
    width: 80%


