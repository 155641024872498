@import "../styles/common"


.main-page
  padding-bottom: 50px
  .product-category-list
    margin-top: 60px
    user-select: none
    overflow-y: auto
    @include no-scrollbar
    opacity: 0

    &.show
      opacity: 1

    &.anchor
      z-index: 91
      position: fixed
      top: 15px
      padding: 5px 0
      left: 0
      width: 100%
      background: white
      display: none
      &.show
        display: block
      @include not-mobile
        width: $not-mobile-container-width
        max-width: 100%
        left: 50%
        transform: translateX(-50%)

    &.loading
      li
        @include opacity-blink
        background: $color-light-gray
        width: 60px
        &:nth-child(0)
          width: 80px
        &:nth-child(1)
          width: 70px
        &:nth-child(2)
          width: 90px
        &:nth-child(3)
          width: 75px

    ul
      margin: 0
      padding: 0
      white-space: nowrap
      padding-left: 16px
      width: 100%
      li
        display: inline-block
        border-radius: 40px
        height: 44px
        line-height: 40px
        padding: 0 11px
        background: $color-tertiary-lighten
        color: $color-tertiary
        margin-right: 8px
        font-family: NotoSans
        font-weight: bold
        font-size: 14px
        border: 2px solid transparent
        transition: border .3s
        &.active
          border-color: $color-tertiary
