@import "../styles/common"

.sector-selector-container
  position: relative
  .sector-selector-contents
    position: absolute
    z-index: 5
    top: -70px
    width: 100%

    .sector-selector-contents-inner
      transition: opacity .3s
      opacity: 1
      display: flex         // Set the container to use Flexbox
      justify-content: center  // Horizontally center the child
      align-items: center
      &.disabled
        opacity: .5

      position: relative
      @include standard-box-shadow-straight
      width: 180px
      height: 52px
      margin: 0 auto
      border-radius: 30px
      overflow: hidden
      background: $color-secondary!important
      .icon.arrow-down
        color: white!important
        background-color:white
      .cursor
        position: absolute
        left: 0
        top: 0
        height: 52px
        width: 52px
        border-radius: 30px
        background: $color-secondary
        color: white
        z-index: 6
        transition: left .3s, width .3s
      .sector-selector
        cursor: pointer
        display: flex
        align-items: center
        justify-content: center
        z-index: 7
        font-family: SourceSansPro
        font-size: 16px
        transition: color .3s
        line-height: 23px
        max-width: 135px
        text-align: center
        overflow: hidden
        text-overflow: ellipsis
        text-align: center
        color: white
        &.active
          color: white
        &:first-child
          left: 0
        &:last-child
          right: 0

      &.single
        .sector-selector
          color: #2E3345
          width: 100%
          max-width: 100%