@import "../styles/common"

$slider-height: 420px

.slider-carousel
  height: $slider-height
  .control-dots
    bottom: 30px
    color: $color-secondary
    svg
      margin: 0 2px
  .main-slider
    height: $slider-height
    position: relative
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    @include not-mobile
      background-size: 100%
    .main-slider-overlay
      position: absolute
      left: 0
      top: 0
      height: 100%
      width: 100%
      background: rgba(0, 0, 0, 0.3)
      z-index: 1

    .slider-contents
      text-align: left
      position: absolute
      z-index: 2
      left: 0
      top: 0
      width: 100%
      color: white
      .sub-header
        font-family: SourceSansPro
        font-weight: 600
        font-size: 14px
        margin: 200px 24px 0
      .header
        font-family: NotoSans
        font-weight: bold
        font-size: 24px
        margin: 5px 24px 0