.icon
  display: inline-block
  width: 16px
  height: 16px
  background-size: 100%
  background-repeat: no-repeat

  $icons: share, left-white, left-black, right-black, star, star-gray, plus, cart, close, info, language, menu, question, refresh, search, warning, sector-down, refresh-time, radio-primary-active, radio-primary, radio-primary-disabled, checkbox-primary-active, checkbox-primary, checkbox-primary-disabled, minus-gray-outline, plus-green-outline, leaf, image-placeholder, arrow-down, arrow-down-black, arrow-up-blue, megaphone

  @each $icon in $icons
    &.icon-#{$icon}
      background-image: url("../assets/common/icons/#{$icon}.svg")

  svg 
    width: 100%
    height: 100%
    object-fit: contain  
    display: block 