@font-face
  font-family: Impact
  src: url('fonts/Impact/impact.ttf') format('truetype')

@font-face
  font-family: NotoSans
  src: url('fonts/Noto_Sans/NotoSans-Regular.ttf') format('truetype')
  font-weight: normal

@font-face
  font-family: NotoSans
  src: url('fonts/Noto_Sans/NotoSans-Bold.ttf') format('truetype')
  font-weight: bold

@font-face
  font-family: SourceSansPro
  src: url('fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('truetype')
  font-weight: normal

@font-face
  font-family: SourceSansPro
  src: url('fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('truetype')
  font-weight: 600

@font-face
  font-family: SourceSansPro
  src: url('fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('truetype')
  font-weight: bold

@font-face
  font-family: Roboto
  src: url('fonts/Roboto/Roboto-Regular.ttf') format('truetype')
  font-weight: normal

@font-face
  font-family: Roboto
  src: url('fonts/Roboto/Roboto-Medium.ttf') format('truetype')
  font-weight: 500
