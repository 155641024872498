@import "../../../styles/common"
@import "../icons"

.checkout-steps
  position: relative
  height: 50px
  width: 60%
  margin: 23px auto

  .lines
    position: relative
    width: 100%
    z-index: 1

    .line
      position: absolute
      top: 15px
      width: 50%
      border-bottom: 2px dashed $color-gray-2
      height: 2px
      &.active
        border-color: $color-secondary
      &.line-left
        left: 0
      &.line-right
        right: 0

  .steps
    position: relative
    width: 100%
    z-index: 2
    .step
      position: absolute
      text-align: center

      .icon
        display: inline-block
        width: 32px
        height: 32px
        @extend .checkout-icon

      &.active 
        .icon 
          color: $color-secondary

      .label
        font-family: SourceSansPro
        font-size: 12px
        color: $color-text-1
        &.active
          font-weight: bold
          color: $color-secondary

      &.step-1
        left: 0
        transform: translateX(-50%)
        
      &.step-2
        left: 50%
        transform: translateX(-50%)

      &.step-3
        right: 0
        transform: translateX(50%)


  
