.image-block
  width: 100%
  height: 100%
  overflow: hidden

  display: flex
  align-items: center
  justify-content: center

  img
    display: block
    max-width: 100%
    max-height: 100%
    margin: 0 auto

  &.squared
    position: relative

    &:after
      content: ""
      display: block
      padding-bottom: 80%

    img
      width: 100%
      max-height: 100%
      position: absolute
      object-fit: cover

  .image-placeholder
    height: 100%
    width: 100%
    background: #CAD5DD
    display: flex
    align-items: center
    justify-content: center

    .icon
      width: 20%
      height: 100%
      background-position: center center

