@import "./../../styles/common"

.kitchen-notification
  display: flex
  align-items: flex-start
  font-family: SourceSansPro
  font-size: 16px
  background-color: $color-highlight-3
  &.closed
    background-color: $color-gray-5
  color: white
  padding: 15px
  .icon
    width: 24px
    height: 24px
  .text
    margin-left: 15px
    .header
      font-weight: bold


