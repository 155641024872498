@import "../../styles/icons"

.checkout-icon
  @extend .icon

  $icons: step1-active, step2, step2-active, step2-point, step2-point-active, step3, step3-active, cart, cart-2, sector-input-separator, point, point-white, phone-white, close-white, arrow-right-white, time-white, point-empty, point-completed, empty-placeholder, danger, time-black, arrow-right-white-2, point-error, danger-circle-red

  @each $icon in $icons
    &.checkout-icon-#{$icon}
      background-image: url("../../assets/checkout/#{$icon}.svg")
