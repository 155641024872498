@import "../styles/common"

body
  overflow: auto
  &.left-menu-open
    overflow: hidden
    @include not-mobile
      overflow: auto

.drawer-container
  position: fixed
  width: 100%
  left: -100%
  top: 0
  height: 100%
  z-index: $left-menu-z-index
  background: white
  transition: left .5s
  overflow: auto

  &.open
    left: 0

  @include not-mobile
    width: 400px
    left: -400px
    box-shadow: 0 4px 8px 0 rgba(34, 60, 80, 0.2)
    .open
      left: 0


  .drawer-header
    @include top-gradient-background-opacity
    border-bottom-right-radius: 40px
    padding: 30px 16px
    .icons
      .icon-left-white
        width: 24px
        height: 24px

    .image-container
      margin-top: 15px
      .image
        width: 66px
        margin: 0 auto
        img
          display: block

    .link-label-container
      margin-top: 20px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

      .icon
        width: 27px
        height: 27px
      .label
        margin-left: 10px
        font-weight: 600
        font-family: SourceSansPro
        font-size: 14px
        text-align: center
        color: white


  .orderly-main-label
    margin-top: 30px

  .drawer-menu
    margin: 30px
    .title-container
      display: flex
      align-items: center

      .title
        color: $drawer-title-color
        font-size: 16px
        font-weight: bold
        font-family: SourceSansPro
        flex-grow: 1
        a
          color: inherit
          text-decoration: inherit

      .icon
        margin-right: 10px
        width: 20px
        height: 20px
        flex-shrink: 0

    .right-icon
      position: absolute
      right: 0
      top: 0
      width: 23px
      height: 23px

    .comment
      margin-top: 13px
      font-size: 16px
      color: $drawer-comment-color
      margin-bottom: -5px

    ul
      list-style: none
      padding: 0
      li
        position: relative
        border-bottom: 1px solid $drawer-separator-color
        &:last-child
          border-bottom: none
        padding-top: 20px
        padding-bottom: 20px

  .bottom
    margin: 30px
    font-size: 14px
    font-family: SourceSansPro
    color: $drawer-title-color

    .phone, .email
      color: $color-dark-green-1
      margin-top: 12px

      a
        font-weight: bold
        color: inherit
        text-decoration: inherit

    .logo
      margin-top: 20px
      margin-left: -5px

