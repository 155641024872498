@import "../styles/common"


.down-stick
  z-index: 110
  position: fixed
  width: 100%
  max-width: 600px
  border-radius: 20px 20px 0 0
  background: white
  @include standard-box-shadow-straight-strong
  padding: 12px 16px 16px

  left: 50%
  bottom: 0
  transition: bottom .3s, transform .3s
  transform: translateY(100%) translateX(-50%)

  &.show
    bottom: 0
    transform: translateY(0) translateX(-50%)

.no-transition .down-stick
  transition: none!important

.bb-button-container.down-stick
  position: static
  transform: none
  transition: none
  margin: 0 auto
  padding-top: 15px!important
